<template>
    <div class="system-jobs">
        <loader v-if="!hasLoaded"></loader>

        <table v-if="hasLoaded" class="table table-striped">
            <thead>
                <tr>
                    <th class="col-xs-2">
                        {{$t('system.jobs.queue')}}
                    </th>

                    <th class="col-xs-6">
                        {{$t('system.jobs.payload')}}
                    </th>

                    <th class="col-xs-2">
                        {{$t('system.jobs.date')}}
                    </th>

                    <th class="col-xs-2">

                    </th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(job, index) in jobs" :class="{processing: isProcessing(index)}">
                    <td>
                        {{job.queue}}
                    </td>

                    <td>
                        <loader v-if="isProcessing(index)" size="small"></loader>
                        <strong v-if="firstLine(job.exception)">{{firstLine(job.exception)}}</strong>
                        {{job.payload | substr(0, 500) }}
                    </td>

                    <td>
                        {{job.failedAt.date | moment("calendar") }}
                    </td>

                    <td>
                        <button type="button" class="btn btn-primary btn-xs pull-right" @click="release(index)">
                            {{$t('system.jobs.release')}}
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style lang="scss">
    .system-jobs {
        tr {
            position: relative;

            strong {
                display: block;
            }

            &.processing {
                td {
                    position: relative;

                    &:after {
                         position: absolute;
                         left: 0;
                         top: 0;
                         width: 100%;
                         height: 100%;
                         background-color: rgba(0, 0, 0, 0.6);
                         content: " ";
                         z-index: 9;
                     }

                     .loader {
                         color: #fff;
                         position: absolute;
                         z-index: 10;
                         font-size: 22px;
                         left: 50%;
                         top: 50%;
                         margin-top: -14px;
                     }
                }

            }
        }
    }
</style>

<script>
    import Loader from '@/app/shared/components/Loader.vue'
    import QuarantinedMails from '@/app/system/components/QuarantinedMails.vue'

    const moment = require('moment')

    const JobService = require('@/services/system/JobService')

    export default {
        name: 'Quarantine',
        data() {
            return {
                hasLoaded: false,
                jobs: [],
                processing: []
            }
        },
        mounted() {
            this.load();
        },
        methods: {
            load() {
                var data = {
                    sort: [
                        {
                            key: 'failedAt',
                            direction: 'DESC'
                        }
                    ]
                };

                JobService.findAll(data, (response) => {
                    var body = response.data;

                    this.jobs = body.rows;
                    this.hasLoaded = true;
                }, (response) => {
                    this.hasLoaded = true;
                });
            },

            release(index) {
                if (this.processing.indexOf(index) != -1) {
                    return;
                }

                this.processing.push(index);

                var $this = this;

                var job = this.jobs[index];

                JobService.release(job.id, (response) => {
                    this.jobs = this.jobs.filter(function (job) {
                        return (job.id != $this.jobs[index].id);
                    });

                    this.processing = this.processing.filter(function (jobIndex) {
                        return (jobIndex != index);
                    });
                });
            },

            /**
             *
             * @param index
             * @returns {boolean}
             */
            isProcessing(index) {
                return (this.processing.indexOf(index) != -1);
            },

            /**
             * First line
             *
             * @param message
             * @returns {*}
             */
            firstLine(message) {
                var lines = message.split('Stack trace');

                if (!lines.length) {
                    return;
                }

                return lines[0];
            }
        },
        components: {
            Loader,
            QuarantinedMails
        }
    }
</script>
