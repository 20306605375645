<template>
    <div class="total-pending">
        <card icon="pe-7s-mail" :headline="$t('mailCenter.mails')">
            <loader v-if="!hasLoaded" size="small"></loader>
            <span class="lead" v-if="hasLoaded">{{$t('mailCenter.totalPending')}}: {{pending}}</span>
        </card>
    </div>
</template>

<script>
    import Card from '@/app/shared/components/Card'
    import Loader from '@/app/shared/components/Loader.vue'

    const moment = require('moment');

    export default {
        data() {
            return {
                hasLoaded: false,
                pending: null,
                interval: null
            }
        },
        mounted() {
            this.load();

            this.interval = setInterval(this.load, 8000);
        },
        destroyed() {
            clearInterval(this.interval);
        },
        methods: {
            load() {
                var data = {
                    limit: 1,
                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'sent',
                                    value: '',
                                    operator: 'eq'
                                }
                            ]
                        }
                    ]
                };

                this.$http.get('mail-center', {params: data}).then((response) => {
                    var body = response.data;

                    this.pending = body.total;
                    this.hasLoaded = true;
                }, (response) => {
                    this.hasLoaded = true;
                });
            }
        },
        components: {
            Loader,
            Card
        }
    }
</script>
