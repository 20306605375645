<template>
    <div class="system-mails">
        <mail-queues :from="from" :to="to"></mail-queues>
    </div>
</template>

<style lang="scss">
    .system-mails {
    }
</style>

<script>
    import Loader from '@/app/shared/components/Loader.vue'
    import MailGraph from '@/app/system/components/MailGraph.vue'
    import MailQueues from '@/app/system/components/MailQueues.vue'

    const moment = require('moment');

    export default {
        name: 'Mails',
        data() {
            return {
                from: moment().startOf('hour').add(1, 'hour').subtract(24, 'hours'),
                to: moment().startOf('hour').add(2, 'hour')
            }
        },
        components: {
            Loader,
            MailGraph,
            MailQueues
        }
    }
</script>
