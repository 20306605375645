<template>
    <div class="input-group">
        <span class="input-group-addon">{{$t('mailCenter.limit')}}:</span>
        <input type="text" name="limit" v-model="hourlyLimit" class="form-control">

        <span class="input-group-btn">
            <button type="button" @click="save" class="btn btn-primary">
                <span v-if="!hasLoaded" class="fa fa-spin fa-spinner fa-fw"></span>
                {{$t('mailCenter.save')}}
            </button>
        </span>
    </div>
</template>

<style lang="scss">
</style>

<script>
    import * as QueueService from '@/services/mail-center/QueueService'

    export default {
        props: ['type'],

        data() {
            return {
                hasLoaded: true,
                localType: null,
                hourlyLimit: 0
            }
        },

        computed: {
            parentHourlyLimit() {
                return this.type.hourlyLimit
            }
        },

        watch: {
            parentHourlyLimit() {
                if (this.parentHourlyLimit !== this.localType.hourlyLimit) {
                    this.localType = this.type
                }
            },
            localType() {
                this.hourlyLimit = this.localType.hourlyLimit
            }
        },

        mounted() {
            this.localType = this.type
        },

        methods: {
            save() {
                if (!this.hasLoaded) {
                    return
                }

                this.hasLoaded = false

                const data = {
                    hourlyLimit: this.hourlyLimit
                }

                QueueService.put(this.localType.id, data, (response) => {
                    this.hasLoaded = true
                })
            }
        }
    }
</script>
