<template>
    <div class="chart-mails-sent">
        <loader v-if="!hasLoaded"></loader>

        <div class="chart"></div>
    </div>
</template>

<style lang="scss">

    .chart-mails-sent {
        width: 100%;
        height: 400px;

        .loader {
            padding-top: 150px;
        }
    }
</style>

<script>
  import Loader from '@/app/shared/components/Loader.vue'

  const moment = require('moment')
  const HighCharts = require('highcharts')
  import {groupBy} from 'lodash'

  const MailCenterService = require('@/services/system/MailCenterService')

  export default {
    props: ['from', 'to', 'type', 'global'],
    data () {
      return {
        chart: null,
        items: [],
        hasLoaded: false,
        interval: null
      }
    },
    watch: {
      type () {
        this.reload()
      },

      from () {
        this.reload()
      },

      to () {
        this.reload()
      }
    },
    mounted () {
      this.load()

      this.interval = setInterval(this.load, 5000)
    },
    destroyed () {
      clearInterval(this.interval)
    },
    methods: {
      reload () {
        if (this.chart) {
          this.chart.destroy()
        }

        this.chart = null
        this.hasLoaded = false
        this.items = []

        this.load()
      },
      load () {
        var from = this.from
        var to = moment(this.to)

        let data = {
          filter_groups: [
            {
              filters: [
                {
                  key: 'created',
                  value: from.format('Y-MM-DD'),
                  operator: 'gt'
                },
                {
                  key: 'created',
                  value: to.add(1, 'days').format('Y-MM-DD'),
                  operator: 'lt'
                }
              ]
            }
          ]
        }

        if (this.type && !this.global) {
          data.filter_groups[0].filters.push({
            key: 'type',
            value: this.type,
            operator: 'eq'
          })
        }

        MailCenterService.findAll(data, (response) => {
          var body = response.data

          if (JSON.stringify(this.items) === JSON.stringify(body.rows) && this.hasLoaded) {
            return
          }

          this.items = body.rows

          this.hasLoaded = true

          this.render()
        }, (response) => {
          this.hasLoaded = true
        })
      },

      render () {
        var labels = []

        var series = {
          pending: {
            name: $t('mailCenter.pending'),
            data: []
          },
          sent: {
            name: $t('mailCenter.sent'),
            data: []
          }
        }

        var groups = groupBy(this.items, function (item) {
          return moment(item.created.date).format('Y-MM-DD H')
        })

        for (var m = moment(this.from); m.isBefore(this.to); m.add(1, 'hour')) {
          labels.push(m.format('H:mm'))

          var data = groups[m.format('Y-MM-DD H')]

          if (!data) {
            series.pending.data.push(0)
            series.sent.data.push(0)

            continue
          }

          series.pending.data.push(data.filter(function (item) {
            return item.sent == null
          }).length)

          series.sent.data.push(data.filter(function (item) {
            return item.sent != null
          }).length)
        }

        var $elm = this.$el.querySelector('.chart')

        this.chart = new HighCharts.Chart($elm, {
          title: {
            text: $t('mailCenter.mails')
          },
          credits: false,
          chart: {
            type: 'column',
          },
          xAxis: {
            categories: labels,
            crosshair: true
          },
          yAxis: {
            min: 0,
            title: {
              text: $t('mailCenter.mails')
            }
          },
          tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.0f} mails</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
          },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0
            }
          },
          series: [
            series.pending,
            series.sent
          ]
        })
      }
    },
    components: {
      Loader
    }
  }
</script>
