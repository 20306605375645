const BaseService = require('@/services/BaseService')

const namespace = 'mail-center'

const findAll = (options, cb, errorCb) => {
  BaseService.get(namespace, options, cb, errorCb)
}

const queues = (options, cb, errorCb) => {
  BaseService.get(namespace + '/queues', options, cb, errorCb)
}

export {
  findAll,
  queues
}
