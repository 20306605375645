<template>
    <div class="quarantine">
        <loader v-if="!hasLoaded"></loader>

        <table v-if="hasLoaded" class="table table-striped">
            <thead>
                <tr>
                    <th class="col-xs-4">
                        E-mail
                    </th>

                    <th class="col-xs-4">
                        Customer
                    </th>

                    <th class="col-xs-2">
                        Mails
                    </th>

                    <th class="col-xs-2">

                    </th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(user, index) in users">
                    <td>
                        {{user.email}}
                    </td>

                    <td>
                        {{user.customer.name}} ({{user.customer.domain}})
                    </td>

                    <td>
                        <quarantined-mails :user="user"></quarantined-mails>
                    </td>

                    <td>
                        <button type="button" class="btn btn-primary btn-xs pull-right" @click="release(index)">
                            Release
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style lang="scss">
    .system-mails {
    }
</style>

<script>
    import Loader from '@/app/shared/components/Loader.vue'
    import QuarantinedMails from '@/app/system/components/QuarantinedMails.vue'

    const moment = require('moment')

    const ClientService = require('@/services/users/ClientService')

    export default {
        name: 'Quarantine',
        data() {
            return {
                hasLoaded: false,
                users: [],
            }
        },
        mounted() {
            this.load();
        },
        methods: {
            load() {
                var data = {
                    includes: ['customer', 'mailQuarantine'],
                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'mailQuarantine',
                                    value: 'NULL',
                                    operator: 'eq',
                                    not: true
                                }
                            ]
                        }
                    ]
                };

                ClientService.findAll(data, (response) => {
                    var body = response.data;

                    this.users = body.rows;
                    this.hasLoaded = true;
                }, (response) => {
                    this.hasLoaded = true;
                });
            },

            release(index) {
                var user = this.users[index];

                this.$http.delete('mail-center/quarantine/' + user.mailQuarantine.id).then((response) => {
                    this.load();
                });
            }
        },
        components: {
            Loader,
            QuarantinedMails
        }
    }
</script>
