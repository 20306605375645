<template>
    <span>
        <button class="btn btn-block btn-round" @click="toggle" :class="{'btn-danger': isStopped, 'btn-success': !isStopped}">
            <span v-if="!hasLoaded" class="fa fa-spin fa-spinner fa-fw"></span>

            <template v-else>
                <template v-if="!this.isStopped">
                    {{$t('mailCenter.clickToStop')}}
                </template>
                <template v-else>
                    {{$t('mailCenter.clickToStart')}}
                </template>
            </template>

        </button>
    </span>
</template>

<script>
    const MailCenterService = require('@/services/system/MailCenterService')

    import * as QueueService from '@/services/mail-center/QueueService'

    export default {
        props: ['type'],

        data() {
            return {
                hasLoaded: true,
                localType: null,
                interval: null
            }
        },

        watch: {
            type() {
                this.localType = this.type
            }
        },

        mounted() {
            this.localType = this.type
            this.interval = setInterval(this.load, 2500)
        },

        destroyed() {
            clearInterval(this.interval)
        },

        computed: {
            isStopped() {
                if (!this.localType) {
                    return false
                }

                return this.localType.stopped
            }
        },
        methods: {
            load() {
                if (!this.hasLoaded) {
                    return false;
                }

                QueueService.find(this.localType.id, {}, (response) => {
                    this.localType = response.data
                })
            },

            toggle() {
                if (!this.hasLoaded) {
                    return
                }

                const data = {
                    stopped: (this.localType.stopped) ? 0 : 1
                }

                this.hasLoaded = false

                QueueService.put(this.localType.id, data, (response) => {
                    this.localType = response.data
                    this.hasLoaded = true
                })
            }
        }
    }
</script>
