<template>
    <div class="mail-queues">
        <loader v-if="!hasLoaded"></loader>

        <template v-if="hasLoaded">
            <div class="row">
                <div class="col-md-12">
                    <div v-if="globalType.stopped" class="alert alert-danger text-center">
                        <strong class="lead">{{$t('mailCenter.globalQueueStopped')}}</strong>
                    </div>

                    <div class="panel panel-default">
                        <div class="panel-body">
                            <div class="row">
                                <div class="col-md-8 col-lg-9">
                                    <mail-graph :from="from" :to="to" :type="globalType.id" global="1"></mail-graph>
                                </div>

                                <div class="col-md-4 col-lg-3">
                                    <total-pending></total-pending>

                                    <div class="form-group">
                                        <mail-limit :type="globalType"></mail-limit>
                                    </div>

                                    <div class="form-group">
                                        <mail-status :type="globalType"></mail-status>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <div class="row">
                <div class="col-md-12">
                    <tab-navigation>
                        <ul>
                            <li v-for="(queue, inx) in filteredQueues" :class="{'router-link-active': (inx == index)}" @click="setActive(inx)">
                                <a :class="{'router-link-active': (inx == index)}">
                                    <span :class="{'text-danger': queue.stopped}">{{queue.title}}</span>
                                </a>
                            </li>
                        </ul>

                        <div class="clearfix"></div>

                        <hr />

                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <mail-limit :type="type"></mail-limit>
                                    </div>

                                    <div class="col-md-6">
                                        <mail-status :type="type"></mail-status>
                                    </div>
                                </div>

                                <hr />

                                <mail-graph :from="from" :to="to" :type="type.id"></mail-graph>
                            </div>
                        </div>
                    </tab-navigation>
                </div>
            </div>
        </template>
    </div>
</template>

<style lang="scss">
    .mail-queues {
        .nav {
            li {
                cursor: pointer;
            }
        }
    }
</style>

<script>
import Loader from '@/app/shared/components/Loader.vue'
import MailGraph from './MailGraph.vue'
import MailLimit from './MailLimit.vue'
import MailStatus from './MailStatus.vue'
import TotalPending from './TotalPending.vue'
import TabNavigation from '@/app/layout/components/TabNavigation'

const moment = require('moment')

import * as QueueService from '@/services/mail-center/QueueService'

export default {
    props: ['from', 'to'],
    data() {
        return {
            hasLoaded: false,
            queues: null,
            index: null,
            type: null,
            interval: null,
            globalQueue: null
        }
    },

    watch: {
        index() {
            this.type = this.filteredQueues[this.index]
        }
    },

    computed: {
        filteredQueues() {
            return this.queues.filter((item) => {
                return (!item.global)
            });
        },

        globalType() {
            const global = this.queues.filter((item) => {
                return (item.global)
            })

            if (!global.length) {
                return null
            }

            return global[0]
        }
    },

    mounted() {
        this.load()

        this.interval = setInterval(this.load, 2500)
    },

    destroyed() {
        clearInterval(this.interval)
    },

    methods: {
        load() {
            QueueService.findAll({}, (response) => {
                const data = response.data

                this.queues = data.rows;

                if (!this.hasLoaded) {
                    this.index = 0
                }

                this.hasLoaded = true
            }, () => {
                this.hasLoaded = true
            })
        },

        setActive(index) {
            this.index = index
        }
    },
    components: {
        Loader,
        MailGraph,
        MailLimit,
        MailStatus,
        TotalPending,
        TabNavigation
    }
}
</script>
