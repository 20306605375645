const BaseService = require('@/services/BaseService')

const namespace = 'queue/jobs'

const findAll = (options, cb, errorCb) => {
  BaseService.get(namespace, options, cb, errorCb)
}

const release = (id, cb, errorCb) => {
  BaseService.post(namespace + '/' + id + '/release', {}, cb, errorCb)
}

export {
  findAll,
  release
}
