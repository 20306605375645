const BaseService = require('@/services/BaseService')

const namespace = 'mail-center/queues'

const find = (id, options, cb, errorCb) => {
    BaseService.get(namespace + '/' + id, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
    BaseService.get(namespace, options, cb, errorCb)
}

const put = (id, data, cb, errorCb) => {
    BaseService.put(namespace + '/' + id, data, cb, errorCb)
}

export {
    find,
    findAll,
    put
}
