<template>
    <span>
        <span class="fa fa-spin fa-spinner fa-fw" v-if="!hasLoaded"></span>
        <span v-else>{{mails}}</span>
    </span>
</template>

<style lang="scss">
</style>

<script>
    const MailCenterService = require('@/services/system/MailCenterService')

    export default {
        name: 'QuarantinedMails',
        props: ['user'],
        data() {
            return {
                hasLoaded: false,
                mails: 0
            }
        },
        mounted() {
            this.load();
        },
        methods: {
            load() {
                var data = {
                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'user',
                                    value: this.user.id,
                                    operator: 'eq'
                                },
                                {
                                    key: 'sent',
                                    value: 'NULL',
                                    operator: 'eq'
                                }
                            ]
                        }
                    ]
                };

                MailCenterService.findAll(data, (response) => {
                    var body = response.data;

                    this.mails = body.total;
                    this.hasLoaded = true;
                }, (response) => {
                    this.hasLoaded = true;
                });
            }
        }
    }
</script>
